<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mb-1"
      @click="cadastrarContatos"
    >
      Cadastrar Contatos
    </b-button>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mb-1"
      @click="criarContatosUser"
    >
      Contatos User
    </b-button>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mb-1"
      @click="criarPerfilUser"
    >
      Criar perfil User
    </b-button>
    <b-table
      :fields="fields"
      :items="items"
    >
      <template #cell(fullName)="data">
        <a :href="`#${data.value.replace(/[^a-z]+/i,'-').toLowerCase()}`">{{ data.value }}</a>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BButton } from 'bootstrap-vue'
import axios from '@/../axios-auth'
import firebase from '@/../firebase'

export default {
  components: {
    BTable,
    BButton,
  },
  data() {
    return {
      fields: [
        'id',
        {
          key: 'fullName',
          label: 'Nome',
        },
        {
          key: 'role',
          label: 'Função',
        },
      ],
      items: [],
    }
  },
  created() {
    axios.get('api/v1/funcionarios', {
      headers: {
        Accept: 'Application/json',
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
      },
    }).then(res => {
      const contacts = []
      const Data = res.data.dados
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const prop in Data) {
        contacts.push({
          id: Data[prop].usuario_empresas.user_id,
          fullName: Data[prop].nome,
          role: Data[prop].funcao.nome,
          about: 'Orgulho de ser VOZ',
          avatar: '',
          status: 'offline',
          roleId: Data[prop].funcao_id,
        })
      }
      this.items = contacts
    })
  },
  methods: {
    cadastrarContatos() {
      firebase.ref('contacts').set(this.items)
    },
    criarContatosUser() {
      firebase.ref('users/15/contacts').set(this.items)
    },
    criarPerfilUser() {
      const data = {
        about: 'Orgulho de ser VOZ',
        avatar: '',
        fullName: localStorage.getItem('userName'),
        id: localStorage.getItem('userId'),
        role: 'Desenvolvedor',
        settings: {
          isNotificationsOn: false,
          isTwoStepAuthVerificationEnabled: true,
        },
      }
      firebase.ref('users/15/profileUser').set(data)
    },
  },
}
</script>
